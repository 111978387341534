// src/utils/prompts.ts

import { Dayjs } from "dayjs";

export const getPrompt = (tripName: string, startDate: Dayjs, tripDescription: string) => {
  const prompt = `Generate the JSON representation of a trip called "${tripName}",
  in which the user will arrive on ${startDate.format("YYYY-MM-DD")}.

  They provided the following additional details that you should incorporate as long as they are realistic and law-abiding and safe: "${tripDescription}".

  Please use the following JSON structure in your response such that each city is represented as a separate stop and the total number of days in the days list corresponds to the total number of nights in the stop:
  [
    {
      "name": "A recognizable city that can be easily found on google maps",
      "accommodation": "A recommended neighborhood / hotel in which to stay for the entire stop"
      "days": [
        {
          "description": "Description of activities for the day. If the previous day was a different stop, please include information about how they can travel to the new location"
        },
        // etc
      ]
    },
    // etc
  ].

  A few additional requirements-
  * Please make the last day in the last stop the departure day. Do NOT put the departure day in its own stop as it confuses the user.
  * If the user specifies a length of time (e.g. two weeks), please have the itinerary fill the requested length. For example, a 2-week trip could be 7 stops with 2 days in each stop or it could be 2 stops with 7 days in each stop.
  
  Please only include the JSON in your response.`;

  return prompt;
};
