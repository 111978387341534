// src/components/DefineTrip.tsx

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDefineTrip } from "../hooks/useDefineTrip";
import { Stop, Trip } from "../models/tripModels";

interface DefineTripProps {
  tripName: string;
  setTripName: React.Dispatch<React.SetStateAction<string>>;
  startDate: Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  tripDescription: string;
  setTripDescription: React.Dispatch<React.SetStateAction<string>>;
  setStops: (stops: Stop[]) => void;
  isAuthenticated: boolean;
  handleCreateTrip: (trip: Trip) => void;
  resetState: () => void;
}

const DefineTrip = (props: DefineTripProps) => {
  const {
    tripName,
    setTripName,
    startDate,
    setStartDate,
    tripDescription,
    setTripDescription,
    setStops,
    isAuthenticated,
    handleCreateTrip,
    resetState,
  } = props;
  const { isLoading, error, setError, getResponse } = useDefineTrip(setStops, handleCreateTrip, resetState);
  const navigate = useNavigate();

  const [isExamplesDialogOpen, setIsExamplesDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleSubmit = async () => {
    await getResponse(tripName, startDate!, tripDescription);
  };

  const handleExamplesDialogToggle = () => {
    setIsExamplesDialogOpen(!isExamplesDialogOpen);
  };

  return (
    <Box style={{ padding: 20 }}>
      <Modal
        open={isLoading}
        closeAfterTransition
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            backgroundColor: "white",
            borderRadius: 4,
          }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Loading, please wait...
          </Typography>
        </Box>
      </Modal>
      <Dialog
        open={isExamplesDialogOpen}
        onClose={handleExamplesDialogToggle}
        aria-labelledby="examples-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="examples-dialog-title">Example Descriptions</DialogTitle>
        <Tabs
          value={tabValue}
          onChange={(_, newValue: number) => setTabValue(newValue)}
          aria-label="example descriptions tabs"
        >
          <Tab label="Example 1" />
          <Tab label="Example 2" />
          <Tab label="Example 3" />
        </Tabs>
        <DialogContent>
          {tabValue === 0 && <Box>I want to spend a weekend in NYC and eat all of the pizza</Box>}
          {tabValue === 1 && (
            <Box>
              I want to spent 2 weeks in India, with the last weekend at my friend's wedding in Pune. Please also make
              sure that I have time to see the Taj Mahal.
            </Box>
          )}
          {tabValue === 2 && (
            <Box>
              I'm visiting Central America and want to use the following itinerary as a starting point-
              <ul>
                <li>Day 1 - Fly to San Jose, friend picks me up at the airport; spend night in Monteverde</li>
                <li>Day 2 - Hang out in Monteverde; spend night in Monteverde</li>
                <li>Day 3 - Drive to Nosara; spend night in Nosara</li>
                <li>Day 4 - Work / surf; spend night in Nosara</li>
                <li>Day 5 - Work / surf; spend night in Nosara</li>
                <li>Day 6 - Work / surf; spend night in Nosara</li>
                <li>Day 7 - Friend drives me back to San Jose; fly El Salvador; spend night in El Salvador</li>
                <li>Day 8 - Work / surf; spend night in El Salvador</li>
                <li>Day 9 - Surf; spend night in El Salvador</li>
                <li>Day 10 - Surf; fly back home</li>
              </ul>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Box sx={{ p: 0, maxWidth: 800, margin: "auto" }}>
        <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Basic Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Trip Name"
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
                helperText={tripName.length > 75 && `${tripName.length}/100`}
                value={tripName}
                onChange={(e) => {
                  setError("");
                  setTripName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(val) => {
                  setError("");
                  setStartDate(val);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography variant="subtitle1">Additional Info (optional)</Typography>
            <IconButton
              size="small"
              onClick={handleExamplesDialogToggle}
              aria-label="see examples"
              sx={{ "& .MuiSvgIcon-root": { fontSize: "inherit" } }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Box>
          <TextField
            label="Description"
            fullWidth
            multiline
            inputProps={{
              maxLength: 2000,
            }}
            helperText={tripDescription.length > 1500 && `${tripDescription.length}/2000`}
            rows={4}
            value={tripDescription}
            onChange={(e) => setTripDescription(e.target.value)}
          />
        </Paper>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)} disabled={isLoading}>
            Home
          </Button>
          <Tooltip title={!isAuthenticated && "Sign in to create a new trip"}>
            <span>
              <Button variant="contained" onClick={handleSubmit} disabled={!isAuthenticated}>
                Create Trip
              </Button>
            </span>
          </Tooltip>
        </Box>
        {error && (
          <Alert severity="error" onClose={() => setError("")} sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default DefineTrip;
