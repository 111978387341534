// src/hooks/useTripbook.ts

import { useAuthenticator } from "@aws-amplify/ui-react";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Stop, Task, Trip, TripWithId } from "../models/tripModels";
import {
  addNewTrip,
  fetchRecommendedTrips,
  fetchTrips,
  removeTrip,
  updateTripDetails,
  updateTripTasks,
} from "../services/tripService";

export const useTripbook = () => {
  const [tripName, setTripName] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().add(1, "M"));
  const [tripDescription, setTripDescription] = useState("");
  const [stops, setStops] = useState<Stop[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [trips, setTrips] = useState<TripWithId[]>([]);

  const resetState = (resetTrips: boolean = false) => {
    setTripName("");
    setStartDate(dayjs().add(1, "M"));
    setTripDescription("");
    setStops([]);
    setIsEditing(false);
    if (resetTrips) setTrips([]);
  };

  const handleFetchTrips = useCallback(async () => {
    const fetchedTrips = await fetchTrips();
    setTrips(fetchedTrips);
  }, []);

  const handleCreateTrip = useCallback(
    async (trip: Trip) => {
      const newTripId = await addNewTrip(trip);
      handleFetchTrips();
      return newTripId;
    },
    [handleFetchTrips]
  );

  const handleUpdateTripDetails = useCallback(
    async (id: string, name: string, startDate: Dayjs, stops: Stop[], tasks?: Task[]) => {
      await updateTripDetails(id, name, startDate, stops, tasks);
      handleFetchTrips();
    },
    [handleFetchTrips]
  );

  const handleUpdateTripTasks = useCallback(
    async (id: string, tasks: Task[]) => {
      await updateTripTasks(id, tasks);
      handleFetchTrips();
    },
    [handleFetchTrips]
  );

  const handleRemoveTrip = useCallback(
    async (id: string) => {
      await removeTrip(id);
      handleFetchTrips();
    },
    [handleFetchTrips]
  );

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus === "authenticated") {
      handleFetchTrips();
    }
  }, [handleFetchTrips, authStatus]);

  const recommendedTrips = fetchRecommendedTrips();

  return {
    tripName,
    setTripName,
    startDate,
    setStartDate,
    tripDescription,
    setTripDescription,
    stops,
    setStops,
    isEditing,
    setIsEditing,
    trips,
    recommendedTrips,
    resetState,
    handleCreateTrip,
    handleUpdateTripDetails,
    handleUpdateTripTasks,
    handleRemoveTrip,
  };
};
