/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTrip = /* GraphQL */ `query GetTrip($id: ID!) {
  getTrip(id: $id) {
    id
    name
    description
    startDate
    stops
    tasks
    deleted
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTripQueryVariables, APITypes.GetTripQuery>;
export const listTrips = /* GraphQL */ `query ListTrips(
  $filter: ModelTripFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      startDate
      stops
      tasks
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTripsQueryVariables, APITypes.ListTripsQuery>;
