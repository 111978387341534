// src/components/DayCard.tsx

import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { DayWithId } from "../models/tripModels";

interface DayCardProps {
  day: DayWithId;
  index: number;
  stopId: string;
  stopStartDate: Dayjs;
  onDescriptionChange: (id: string, description: string) => void;
  onAccommodationChange: (id: string, description: string) => void;
  onDeleteDay: (id: string) => void;
}

const DayCard: React.FC<DayCardProps> = ({
  day,
  index,
  stopId,
  stopStartDate,
  onDescriptionChange,
  onAccommodationChange,
  onDeleteDay,
}) => {
  const date = stopStartDate.add(index, "day");

  const theme = useTheme();

  return (
    <Draggable key={`${stopId}${day.id}`} draggableId={`${stopId}${day.id}`} index={index}>
      {(provided, snapshot) => {
        const card = (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Card
              sx={{
                width: "100%",
                flexGrow: 1,
                marginBottom: 1,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: 1,
                background: snapshot.isDragging ? theme.palette.grey[200] : undefined,
                zIndex: snapshot.isDragging ? theme.zIndex.tooltip + 1 : "auto",
              }}
            >
              <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      display="inline"
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      {date.format("ddd MMM D, YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => onDeleteDay(day.id)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    marginBottom: 8,
                  }}
                >
                  <TextField
                    label={"Activities"}
                    multiline
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 2000,
                    }}
                    helperText={day.description.length > 1500 && `${day.description.length}/2000`}
                    value={day.description ?? ""}
                    onChange={(e) => onDescriptionChange(day.id, e.target.value)}
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginRight: 16,
                    }}
                  />
                  <TextField
                    label={"Accommodation"}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    helperText={day.accommodation && day.accommodation.length > 75 && `${day.accommodation.length}/100`}
                    value={day.accommodation ?? ""}
                    onChange={(e) => onAccommodationChange(day.id, e.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        );
        if (snapshot.isDragging) {
          return ReactDOM.createPortal(card, document.getElementById("portal")!);
        } else {
          return card;
        }
      }}
    </Draggable>
  );
};

export default DayCard;
