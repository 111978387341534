// src/components/EditTrip.tsx

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import Joyride, { ACTIONS, CallBackProps, STATUS, Step } from "react-joyride";
import { useNavigate, useParams } from "react-router-dom";
import { Day, Stop, StopWithId, Task, TripWithId } from "../models/tripModels";
import { generateTasks } from "../utils/tasks";
import StopCard from "./StopCard";

interface EditTripProps {
  trips: TripWithId[];
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  handleUpdateTripDetails: (id: string, name: string, startDate: Dayjs, stops: Stop[], tasks?: Task[]) => void;
  isNewTrip?: boolean;
  isFirstTrip?: boolean;
}

const EditTrip: React.FC<EditTripProps> = ({
  trips,
  isEditing,
  setIsEditing,
  handleUpdateTripDetails,
  isNewTrip = false,
  isFirstTrip = false,
}) => {
  const { id } = useParams();

  const trip = trips.find((t) => t.id === id)!;

  const [tripName, setTripName] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [stops, setStops] = useState<StopWithId[]>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSubmitChangesModalOpen, setIsSubmitChangesModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [expandedStops, setExpandedStops] = useState<Set<string>>(new Set());

  //TODO: Right now this correctly doesn't show the alert when adding a recommended trip to "my trips" because it doesn't re-initialize the value; should probably fix this
  const [isAlertOpen, setIsAlertOpen] = useState(isNewTrip);

  useEffect(() => {
    if (trip) {
      const stopsWithId = trip.stops.map((s, i) => {
        return { ...s, id: `stop-${i + 1}` };
      });
      setTripName(trip.name);
      setNewTripName(trip.name);
      setStartDate(trip.startDate);
      setStops(stopsWithId);
      setExpandedStops(new Set(stopsWithId.map((stop) => stop.id)));
    }
  }, [trip]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isEditing) {
        // Custom message for beforeunload event does not work in most modern browsers due to security reasons,
        // but setting returnValue to a non-empty string will still trigger the default browser confirmation dialog.
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditing]);

  //TRIP NAME LOGIC

  const [isEditTripNameModalOpen, setIsEditTripNameModalOpen] = useState<boolean>(false);
  const [newTripName, setNewTripName] = useState("");
  const [newTripNameError, setNewTripNameError] = useState("");

  const onNewTripNameChange = (newName: string) => {
    setNewTripName(newName);
    setNewTripNameError("");

    setIsEditing(true);
  };

  const onEditTripNameModalOpen = () => {
    setIsEditTripNameModalOpen(true);
    setNewTripName(tripName);
    setNewTripNameError("");
  };

  const onEditTripNameModalCancel = () => {
    setIsEditTripNameModalOpen(false);
  };

  const onEditTripNameModalConfirm = () => {
    if (!newTripName) {
      setNewTripNameError("Trip name must not be empty");
      return;
    }
    setTripName(newTripName);
    setIsEditTripNameModalOpen(false);
  };

  //DATE LOGIC

  const onStartDateChange = (newDate: Dayjs | null) => {
    setStartDate(newDate);

    setIsEditing(true);
  };

  //STOP NAME LOGIC

  const [isAddStopModalOpen, setIsAddStopModalOpen] = useState<boolean>(false);
  const [newStopName, setNewStopName] = useState("");
  const [newStopNameError, setNewStopNameError] = useState("");

  const onStopNameChange = (newName: string) => {
    setNewStopName(newName);
    setNewStopNameError("");

    setIsEditing(true);
  };

  const onAddStopModalOpen = () => {
    setIsAddStopModalOpen(true);
    setNewStopName("");
    setNewStopNameError("");
  };

  const onAddStopModalCancel = () => {
    setIsAddStopModalOpen(false);
  };

  const onAddStopModalConfirm = () => {
    if (!newStopName) {
      setNewStopNameError("Stop name must not be empty");
      return;
    }
    handleAddStop();
    setIsAddStopModalOpen(false);
  };

  const handleAddStop = () => {
    const maxStop = Math.max(...stops.map((d) => parseInt(d.id.split("-")[1])));

    const newStop: StopWithId = {
      id: `stop-${maxStop + 1}`,
      name: newStopName,
      days: [{ description: "", accommodation: "" }],
    };
    setStops([...stops, newStop]);

    const newExpandedStops = expandedStops;
    newExpandedStops.add(newStop.id);
    setExpandedStops(newExpandedStops);

    setIsEditing(true);
  };

  const handleUpdateStopName = (id: string, newName: string) => {
    setStops(
      stops.map((stop) => {
        if (stop.id === id) {
          return { ...stop, name: newName };
        }
        return stop;
      })
    );

    setIsEditing(true);
  };

  const handleUpdateStopDays = (stopId: string, newDays: Day[]) => {
    setStops(
      stops.map((stop) => {
        if (stop.id === stopId) {
          return { ...stop, days: newDays };
        }
        return stop;
      })
    );
    setError("");

    setIsEditing(true);
  };

  const handleDeleteStop = (id: string) => {
    setStops(stops.filter((stop) => stop.id !== id));

    setIsEditing(true);
  };

  const getStopStartDate = (startDate: Dayjs, index: number): Dayjs => {
    let cumulativeDays = 0;
    for (let i = 0; i < index; i++) {
      cumulativeDays += stops[i].days.length;
    }

    return startDate.add(cumulativeDays, "day");
  };

  const handleExpandClick = (id: string) => {
    setExpandedStops((prevExpandedStops) => {
      const newExpandedStops = new Set(prevExpandedStops);
      if (newExpandedStops.has(id)) {
        newExpandedStops.delete(id);
      } else {
        newExpandedStops.add(id);
      }
      return newExpandedStops;
    });
  };

  // OTHER LOGIC

  const handleEdit = async (replaceTasks: boolean) => {
    //TODO: check if trip has changed before pinging API
    handleUpdateTripDetails(
      trip.id,
      tripName,
      startDate!,
      stops,
      replaceTasks ? generateTasks(startDate!, stops) : trip.tasks
    );
    returnToTripPage();
  };

  const handleCancel = () => {
    if (isEditing) {
      setIsCancelModalOpen(true);
    } else {
      returnToTripPage();
    }
  };

  const returnToTripPage = () => {
    setIsEditing(false);

    if (isNewTrip) {
      navigate(`/trip/new/${trip.id}`, { replace: true });
    } else {
      navigate(`/trip/${trip.id}`, { replace: true });
    }
  };

  const handleSubmit = () => {
    if (stops.filter((s) => s.days.length === 0).length > 0) {
      setError("Please ensure that each stop has at least one night");
      return;
    }

    if (isNewTrip) {
      handleEdit(true);
    } else {
      setIsSubmitChangesModalOpen(true);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableStop") {
      const newStops = Array.from(stops);
      const [reorderedStops] = newStops.splice(result.source.index, 1);
      newStops.splice(result.destination.index, 0, reorderedStops);

      setStops(newStops);
    } else if (result.type.includes("droppableDay")) {
      const stopId = result.type.split("|")[1];

      const newDays = Array.from(stops.find((s) => s.id === stopId)!.days);
      const [reorderedItem] = newDays.splice(result.source.index, 1);
      newDays.splice(result.destination.index, 0, reorderedItem);

      handleUpdateStopDays(stopId, newDays);
    }

    setIsEditing(true);
  };

  const [runTour, setRunTour] = useState(isFirstTrip);

  const [steps] = useState<Step[]>([
    {
      target: "body",
      placement: "center",
      title: "Welcome to your new trip",
      content:
        "We've created your trip and automatically opened it in edit-mode. Customize it to your liking and then click 'Submit changes' to view the finalized itinerary and tasks. Don't worry, you can always make additional changes later.",
      disableBeacon: true,
      locale: { last: "Okay" },
    },
  ]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setRunTour(false);
    }
  };

  return trip === undefined ? (
    <div>Loading</div>
  ) : (
    <Box sx={{ padding: 2 }}>
      <Joyride
        continuous
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Dialog open={isCancelModalOpen} onClose={() => setIsCancelModalOpen(false)} fullWidth>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to go back? All unsaved changes will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCancelModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => returnToTripPage()} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isEditTripNameModalOpen} onClose={onEditTripNameModalCancel} fullWidth>
        <DialogTitle>Edit Trip Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Trip Name"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{
              maxLength: 100,
            }}
            value={newTripName}
            onChange={(e) => onNewTripNameChange(e.target.value)}
            error={newTripNameError !== ""}
            helperText={
              newTripNameError ? newTripNameError : newTripName.length > 75 ? `${newTripName.length}/100` : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditTripNameModalCancel}>Cancel</Button>
          <Button onClick={onEditTripNameModalConfirm}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddStopModalOpen} onClose={onAddStopModalCancel} fullWidth>
        <DialogTitle>Add New Stop</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Stop Name"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{
              maxLength: 100,
            }}
            value={newStopName}
            onChange={(e) => onStopNameChange(e.target.value)}
            error={newStopNameError !== ""}
            helperText={
              newStopNameError ? newStopNameError : newStopName.length > 75 ? `${newStopName.length}/100` : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddStopModalCancel}>Cancel</Button>
          <Button onClick={onAddStopModalConfirm}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSubmitChangesModalOpen} onClose={() => setIsSubmitChangesModalOpen(false)} fullWidth>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to generate new tasks? This will replace the exististing tasks.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleEdit(false)} color="primary">
            Keep current tasks
          </Button>
          <Button onClick={() => handleEdit(true)} color="primary" autoFocus>
            Generate new tasks
          </Button>
        </DialogActions>
      </Dialog>
      {isAlertOpen && (
        <Alert severity="warning" onClose={() => setIsAlertOpen(false)} sx={{ mb: 1 }}>
          Disclaimer: The suggestions provided by this service are generated using OpenAI's GPT model and should be
          verified for accuracy and suitability.
        </Alert>
      )}
      <Stack spacing={2}>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {tripName}
            </Typography>
            <IconButton
              style={{
                marginLeft: 2,
              }}
              onClick={onEditTripNameModalOpen}
              size="medium"
            >
              <EditIcon style={{ fontSize: "inherit" }} />
            </IconButton>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit changes
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <DatePicker label={"Arrival Date"} value={startDate} onChange={onStartDateChange} />
            <Tooltip title="End date is automatically calculated from the number of nights">
              <span>
                <DatePicker
                  label={"Departure Date"}
                  readOnly
                  disabled
                  value={
                    startDate
                      ? startDate.add(
                          stops.reduce((prev, curr) => prev + curr.days.length, -1),
                          "day"
                        )
                      : startDate
                  }
                />
              </span>
            </Tooltip>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="end" sx={{ mt: 1 }}>
            <Button variant={"outlined"} onClick={() => setExpandedStops(new Set())}>
              Collapse all
            </Button>
            <Button variant={"outlined"} onClick={() => setExpandedStops(new Set(stops.map((stop) => stop.id)))}>
              Expand all
            </Button>
          </Stack>
        </Grid>
        {error && (
          <Box sx={{ display: "flex" }}>
            <Alert severity="error" onClose={() => setError("")}>
              {error}
            </Alert>
          </Box>
        )}
      </Stack>
      <Stack spacing={2} sx={{ marginTop: 1 }}>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="stops" type="droppableStop">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {stops.map((stop, index) => (
                    <StopCard
                      key={stop.id}
                      stop={stop}
                      index={index}
                      stopStartDate={getStopStartDate(startDate!, index)}
                      expanded={expandedStops.has(stop.id)}
                      handleExpandClick={handleExpandClick}
                      handleDeleteStop={handleDeleteStop}
                      handleUpdateStopName={handleUpdateStopName}
                      handleUpdateStopDays={handleUpdateStopDays}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div style={{ marginBottom: 16 }}>
            <Tooltip title={stops.length >= 25 ? "You've reached the max number of stops" : ""}>
              <span>
                <Button disabled={stops.length >= 25} onClick={onAddStopModalOpen} startIcon={<AddCircleOutlineIcon />}>
                  Add Stop
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </Stack>
    </Box>
  );
};

export default EditTrip;
