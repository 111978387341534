// src/components/StopCard.tsx

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Day, DayWithId, StopWithId } from "../models/tripModels";
import DayCard from "./DayCard";

interface StopCardProps {
  stop: StopWithId;
  index: number;
  stopStartDate: Dayjs;
  expanded: boolean;
  handleExpandClick: (id: string) => void;
  handleDeleteStop: (id: string) => void;
  handleUpdateStopName: (id: string, newName: string) => void;
  handleUpdateStopDays: (id: string, newDays: Day[]) => void;
}

const StopCard: React.FC<StopCardProps> = ({
  stop,
  index,
  stopStartDate,
  expanded,
  handleExpandClick,
  handleDeleteStop,
  handleUpdateStopName,
  handleUpdateStopDays,
}) => {
  const days: DayWithId[] = stop.days.map((d, i) => ({
    ...d,
    id: `day-${i + 1}`,
  }));

  const onAddDay = () => {
    const maxDay = Math.max(...days.map((d) => parseInt(d.id.split("-")[1])));
    const newDays = [...days, { id: `day-${maxDay + 1}`, description: "", accommodation: "" }];

    handleUpdateStopDays(stop.id, newDays);
  };

  const onDescriptionChange = (id: string, description: string) => {
    const newDays = days.map((d) => {
      if (d.id === id) {
        return { ...d, description };
      }
      return d;
    });

    handleUpdateStopDays(stop.id, newDays);
  };

  const onAccommodationChange = (id: string, accommodation: string) => {
    const newDays = days.map((d) => {
      if (d.id === id) {
        return { ...d, accommodation };
      }
      return d;
    });

    handleUpdateStopDays(stop.id, newDays);
  };

  const onDeleteDay = (id: string) => {
    const newDays = days.filter((day) => day.id !== id);

    handleUpdateStopDays(stop.id, newDays);
  };

  const [isEditStopNameModalOpen, setIsEditStopNameModalOpen] = useState(false);
  const [newStopName, setNewStopName] = useState(stop.name);
  const [newStopNameError, setNewStopNameError] = useState("");

  const onNewStopNameChange = (newName: string) => {
    setNewStopName(newName);
    setNewStopNameError("");
  };

  const onEditStopNameModalOpen = () => {
    setIsEditStopNameModalOpen(true);
    setNewStopName(stop.name);
    setNewStopNameError("");
  };

  const onEditStopNameModalCancel = () => {
    setIsEditStopNameModalOpen(false);
  };

  const onEditStopNameModalConfirm = () => {
    if (!newStopName) {
      setNewStopNameError("Stop name must not be empty");
      return;
    }
    handleUpdateStopName(stop.id, newStopName);
    setIsEditStopNameModalOpen(false);
  };

  const theme = useTheme();

  return (
    <div>
      <Dialog open={isEditStopNameModalOpen} onClose={onEditStopNameModalCancel} fullWidth>
        <DialogTitle>Edit Stop Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Stop Name"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{
              maxLength: 100,
            }}
            value={newStopName}
            onChange={(e) => onNewStopNameChange(e.target.value)}
            error={newStopNameError !== ""}
            helperText={
              newStopNameError ? newStopNameError : newStopName.length > 75 ? `${newStopName.length}/100` : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditStopNameModalCancel}>Cancel</Button>
          <Button onClick={onEditStopNameModalConfirm}>Save</Button>
        </DialogActions>
      </Dialog>
      <Draggable key={stop.id} draggableId={stop.id} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <div style={{ display: "flex", alignItems: "center", width: "100vw" }}>
                <Card
                  sx={{
                    width: "100%",
                    flexGrow: 1,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    boxShadow: 1,
                    background: snapshot.isDragging ? theme.palette.grey[200] : undefined,
                  }}
                >
                  <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ExpandMore
                          expand={expanded}
                          onClick={() => handleExpandClick(stop.id)}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                        <Typography variant="h6" display="inline">
                          {stop.name}
                        </Typography>
                        <IconButton
                          style={{
                            marginLeft: 2,
                          }}
                          onClick={onEditStopNameModalOpen}
                          size="small"
                        >
                          <EditIcon style={{ fontSize: "inherit" }} />
                        </IconButton>
                        {!expanded && (
                          <Typography variant="subtitle1" display="inline" style={{ marginLeft: 4, marginTop: 2 }}>
                            {`(${stop.days.length} night${stop.days.length !== 1 ? "s" : ""})`}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleDeleteStop(stop.id)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <Droppable droppableId={stop.id} type={`droppableDay|${stop.id}`}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {days.map((day, index) => (
                              <DayCard
                                key={`${stop.id}${day.id}`}
                                day={day}
                                index={index}
                                stopId={stop.id}
                                stopStartDate={stopStartDate}
                                onDescriptionChange={onDescriptionChange}
                                onAccommodationChange={onAccommodationChange}
                                onDeleteDay={onDeleteDay}
                              />
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <Tooltip title={days.length >= 25 ? "You've reached the max number of days" : ""}>
                        <span>
                          <Button disabled={days.length >= 25} onClick={onAddDay} startIcon={<AddCircleOutlineIcon />}>
                            Add Day
                          </Button>
                        </span>
                      </Tooltip>
                    </Collapse>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default StopCard;
