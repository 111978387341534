// src/App.tsx

import { Authenticator } from "@aws-amplify/ui-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import Tripbook from "./components/Tripbook";
import ThemeProvider from "./theme";

const useTheme = true;

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return useTheme ? (
    <Authenticator.Provider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
          <Tripbook />
        </ThemeProvider>
      </LocalizationProvider>
    </Authenticator.Provider>
  ) : (
    <Authenticator.Provider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Tripbook />
      </LocalizationProvider>
    </Authenticator.Provider>
  );
};

export default App;
