// src/components/Home.tsx

import { VisibilityOff } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Stop, Task, Trip, TripWithId } from "../models/tripModels";

interface HomeProps {
  trips: TripWithId[];
  recommendedTrips: TripWithId[];
  isAuthenticated: boolean;
}

export const getEndDate = (startDate: Dayjs, stops: Stop[]) => {
  return startDate.add(
    stops.reduce((prev, curr) => prev + curr.days.length, -1),
    "day"
  );
};

export const getTripEndDate = (trip: Trip) => {
  return getEndDate(trip.startDate, trip.stops);
};

const getDaysToEnd = (trip: Trip) => {
  const endDate = getTripEndDate(trip);

  return endDate.diff(dayjs(), "day");
};

const getDescription = (trip: Trip) => {
  const daysToStart = trip.startDate.diff(dayjs(), "day") + 1;
  const daysToEnd = getDaysToEnd(trip);

  const description =
    daysToStart > 1
      ? `Departs in ${daysToStart} days`
      : daysToStart === 1
      ? "Departs tomorrow"
      : daysToStart === 0
      ? "Departs today"
      : daysToEnd >= 0
      ? "In Progress"
      : "Completed";

  return description;
};

const shouldShowWarning = (trip: Trip) => {
  const daysToStart = trip.startDate.diff(dayjs(), "day") + 1;
  const incompleteTasksCount = trip.tasks.filter((task: Task) => !task.completed).length;
  return daysToStart > 0 && daysToStart < 30 && incompleteTasksCount > 0;
};

const Home: React.FC<HomeProps> = ({ trips, recommendedTrips, isAuthenticated }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showCompletedTrips, setShowCompletedTrips] = useState<boolean>(true);

  const renderTrips = (trips: TripWithId[]) => {
    // Filter trips by search string
    const filteredTrips = trips.filter((trip) => trip.name.toLowerCase().includes(searchTerm.toLowerCase()));

    // Separate upcoming and past trips
    const upcomingTrips = filteredTrips.filter((trip) => getDaysToEnd(trip) >= 0);
    const pastTrips = filteredTrips.filter((trip) => getDaysToEnd(trip) < 0);

    // Sort upcoming trips from soonest to farthest
    upcomingTrips.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));

    // Sort past trips from most recent to farthest in the past
    pastTrips.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));

    // Combine the sorted arrays, with upcoming trips first
    const sortedTrips = showCompletedTrips ? [...upcomingTrips, ...pastTrips] : upcomingTrips;

    return (
      <div>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          {trips.length > 0 && (
            <Grid item xs={12} md="auto" sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 2 }}>
              <Box>
                <OutlinedInput
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search trips..."
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={() => searchTerm && setSearchTerm("")}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        size="small"
                        sx={{
                          visibility: searchTerm ? "visible" : "hidden",
                        }}
                      >
                        {searchTerm ? <CloseIcon /> : <VisibilityOff style={{ visibility: "hidden" }} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox checked={showCompletedTrips} onChange={(e) => setShowCompletedTrips(e.target.checked)} />
                }
                label={<Typography variant="body1">Show Completed Trips</Typography>}
              />
            </Grid>
          )}
          <Grid item xs={12} md="auto" sx={{ display: "flex", justifyContent: { md: "flex-end" } }}>
            <Tooltip
              title={
                trips.length >= 100
                  ? "You've reached the max number of trips"
                  : !isAuthenticated
                  ? "Sign in to create a new trip"
                  : ""
              }
            >
              <span>
                <Button
                  disabled={trips.length >= 100 || !isAuthenticated}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/define"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  New Trip
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {sortedTrips.map((trip) => (
            <Grid item key={trip.id} xs={12} sm={6} md={4}>
              <Card elevation={3} style={{ padding: 20 }}>
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {trip.name}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {getDescription(trip)}
                    </Typography>
                    {shouldShowWarning(trip) && (
                      <Tooltip title="Warning: This trip is less than 30 days away with incomplete tasks!">
                        <WarningAmberIcon fontSize="inherit" sx={{ color: "darkorange", marginLeft: 1 }} />
                      </Tooltip>
                    )}
                  </div>
                  <Typography variant="subtitle2" color="textSecondary">
                    {`${trip.stops.reduce((p, c) => p + c.days.length, 0) - 1} nights`}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {`${trip.stops.length} stop${trip.stops.length > 1 ? "s" : ""}`}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to={`/trip/${trip.id}`}>
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {trips.length > 0 && sortedTrips.length === 0 && (
          <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 2, textAlign: "center" }}>
            No Results Found
          </Typography>
        )}
      </div>
    );
  };

  const renderRecommendedTrips = (trips: TripWithId[]) => (
    <Grid container spacing={2}>
      {trips.map((trip) => (
        <Grid item key={trip.id} xs={12} sm={6} md={4}>
          <Card elevation={3} style={{ padding: 20 }}>
            <CardContent>
              <Typography
                variant="subtitle1"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {trip.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {`${trip.stops.reduce((p, c) => p + c.days.length, 0)} nights`}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {`${trip.stops.length} stop${trip.stops.length > 1 ? "s" : ""}`}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/trip/${trip.id}`}>
                View Details
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div style={{ padding: 20 }}>
      {renderTrips(trips)}
      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>
      <Typography variant="h4" sx={{ mt: 0, mb: 2 }}>
        Popular Trips
      </Typography>
      {renderRecommendedTrips(recommendedTrips)}
    </div>
  );
};

export default Home;
