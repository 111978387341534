import { useMemo } from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";

import { palette } from "./palette";
import { shadows } from "./shadows";
import { overrides } from "./overrides";
import { typography } from "./typography";
import { customShadows } from "./custom-shadows";

// ----------------------------------------------------------------------

/*
Colors I like

charcoal gray 36454F
slate blue 6A5ACD
light charcoal gray 5D6975
dusty blue gray 7393B3
slate gray 708090

Fonts I like

Comic Sans MS
*/

export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      //palette: palette(),
      palette: {
        primary: {
          main: "#4f5e68", //TODO: slightly lighter
        },
      },
      typography,
      //shadows: shadows(),
      //customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    []
  );

  const theme = createTheme(memoizedValue);

  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
