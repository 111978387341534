// src/utils/tasks.ts

import { Dayjs } from "dayjs";
import { Stop, Task } from "../models/tripModels";

export const generateTasks = (startDate: Dayjs, stops: Stop[]): Task[] => {
  if (stops.length === 0) {
    return [];
  }

  try {
    //Generate transportation tasks
    const transportationTasks: Task[] = [];
    const accommodationTasks: Task[] = [];

    let cumulativeDays = 0;

    stops.forEach((stop, index) => {
      const stopStartDate = startDate.add(cumulativeDays, "day");
      const stopEndDate =
        //If it's the last stop, have the checkout day be the last day
        index === stops.length - 1
          ? stopStartDate.add(stop.days.length - 1, "day")
          : stopStartDate.add(stop.days.length, "day");

      transportationTasks.push({
        description: `Book transportation to ${stop.name} on ${stopStartDate.format("ddd MMM D, YYYY")}`,
        completed: false,
      });

      accommodationTasks.push({
        description: `Book accommodation at ${stop.name} from ${stopStartDate.format(
          "ddd MMM D, YYYY"
        )} to ${stopEndDate.format("ddd MMM D, YYYY")}`,
        completed: false,
      });
      cumulativeDays += stop.days.length;
    });

    transportationTasks.push({
      description: `Book transportation from ${stops[stops.length - 1].name} on ${startDate
        .add(cumulativeDays - 1, "day")
        .format("ddd MMM D, YYYY")}`,
      completed: false,
    });

    //Concatenate transportation and accommodation tasks, preserving their chronological order
    const updatedTasks = [...transportationTasks, ...accommodationTasks];

    return updatedTasks;
  } catch (e) {
    //TODO: add error handling to UI
    console.log(e);
    return [];
  }
};
