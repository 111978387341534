// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

export const primaryFont = '"Lato", sans-serif';
export const secondaryFont = '"Open Sans", sans-serif';

// ----------------------------------------------------------------------

/*

Navbar -> h6
Popular Trips -> h5
trip title -> h1
column header -> h6

*/

// Updated with ChatGPT from https://mui.com/store/items/minimal-dashboard-free/
export const typography = {
  fontFamily: primaryFont,
  fontSecondaryFamily: secondaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(50), // Increased size for boldness
    ...responsiveFontSizes({ sm: 56, md: 62, lg: 68 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.25,
    fontSize: pxToRem(38),
    ...responsiveFontSizes({ sm: 44, md: 50, lg: 56 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: pxToRem(30),
    ...responsiveFontSizes({ sm: 36, md: 40, lg: 44 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.35,
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ sm: 24, md: 26, lg: 28 }),
  },
  h6: {
    fontWeight: 600,
    lineHeight: 1.45,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 22, md: 24, lg: 26 }),
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body1: {
    fontFamily: secondaryFont, // Use secondary font for body text
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(15), // Reduced for Open Sans
  },
  body2: {
    fontFamily: secondaryFont, // Consistency with body1
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  caption: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "", // Removed uppercase here
  },
};
