// src/components/Footer.tsx

import { Box, Container, Grid, Link, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "./Tripbook";

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[200],
        py: 2, // padding top & bottom
        px: 2, // padding left & right
        mt: "auto", // pushes to bottom
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              {APP_NAME}
            </Typography>
            <Typography variant="body2">Less planning, more traveling</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Quick Links
            </Typography>
            <Link variant="body2" href="#" onClick={() => navigate("/")} color="inherit">
              Home
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" color="text.primary" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body2">
              <Link href="mailto:tripbookai@gmail.com">tripbookai@gmail.com</Link>
            </Typography>
          </Grid>
        </Grid>
        <Box textAlign="center" pt={{ xs: 2, sm: 5 }} pb={{ xs: 5, sm: 0 }}>
          <Typography variant="body2">
            {APP_NAME} © {new Date().getFullYear()} All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
