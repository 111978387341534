// src/components/NavBar.tsx

import AddIcon from "@mui/icons-material/Add";
import GoogleIcon from "@mui/icons-material/Google"; // Import Google icon
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { signInWithRedirect, signOut } from "aws-amplify/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_NAME_SHORT } from "./Tripbook";

interface NavBarProps {
  tripName: string;
  tripDescription: string | null | undefined;
  isEditing: boolean;
  resetState: (resetTrips?: boolean) => void;
  isAuthenticated: boolean;
}

const NavBar = ({ tripName, tripDescription, isEditing, resetState, isAuthenticated }: NavBarProps) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate(navigateTo!);
    setNavigateTo(null);
    resetState();
  };

  const handleNavClick = (path: string) => {
    if (tripName === "" && tripDescription === "" && !isEditing) {
      navigate(path);
    } else {
      setNavigateTo(path);
    }
  };

  const handleSignIn = () => {
    setShowAuthModal(true);
  };

  const handleSignOut = () => {
    signOut();
    resetState(true);
    setShowAuthModal(false);
  };

  //TODO: consider adding back after figuring out how to keep scrollbar from shifting it's position between pages
  const addTripButton = (
    <Tooltip title="Add New Trip">
      <IconButton
        color="inherit"
        onClick={() => handleNavClick("/define")}
        sx={{
          color: "inherit",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
        }}
      >
        <AddIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );

  function handleGoogleSignIn() {
    signInWithRedirect({
      provider: "Google",
    });
  }

  return (
    <div>
      <Dialog open={navigateTo !== null} onClose={() => setNavigateTo(null)} fullWidth>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to go back? All unsaved changes will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNavigateTo(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!isAuthenticated && showAuthModal} onClose={() => setShowAuthModal(false)}>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <Button onClick={handleGoogleSignIn} fullWidth variant="outlined" sx={{ mt: 2 }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: 8 }}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24">
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <Typography variant="body2">Sign In with Google</Typography>
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAuthModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            onClick={() => handleNavClick("/")}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
              fontWeight: 400,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/favicon-96x96.png`}
              alt="App Logo"
              style={{ marginRight: 8, width: 24, height: 24 }}
            />
            {APP_NAME_SHORT}
          </Typography>
          {isAuthenticated && (
            <Button color="inherit" onClick={handleSignOut}>
              Sign Out
            </Button>
          )}
          {!isAuthenticated && (
            <Button color="inherit" onClick={handleSignIn}>
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
