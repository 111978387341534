/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "openai",
            "endpoint": "https://fzsuwwpxkf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4mzm7vddxfb25epppyl7qopady.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-csri7y3fjjdhnksetotjbvs2rq",
    "aws_cognito_identity_pool_id": "us-east-1:29532b84-6da2-4212-9acf-7fdf3e7095f4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DNMku313v",
    "aws_user_pools_web_client_id": "1emjq882hovkefaniv648tl0l3",
    "oauth": {
        "domain": "tripmapper06f00912-06f00912-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.tripbook.ai/",
        "redirectSignOut": "http://localhost:3000/,https://www.tripbook.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
